<template>
<div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Préstamos de documentos</h1>
        </div>
        <br>
        <form v-on:submit.prevent="">
          <div class="form-group">
            <label>Documentos:</label>
            <input type="text" class="form-control" v-model="item.prm_docs" disabled>
          </div>
          <div class="form-group">
            <label>Fecha:</label>
            <input type="text" class="form-control" v-model="fecha" disabled>
          </div>
          <div class="form-group">
            <label>Solicitante:</label>
            <input type="text" class="form-control" v-model="item.prm_solicitante" disabled>
          </div>
          <div class="form-group">
            <label>Descripción:</label>
            <input type="text" class="form-control" v-model="item.prm_descripcion" disabled>
          </div>
          <div class="form-group">
            <label>Usuario:</label>
            <input type="text" class="form-control" v-model="item.usu_nmbre" disabled>
          </div>
          <div class="form-group">
            <label>Activo:</label>
            <input type="checkbox" class="form-control" v-model="item.prm_activo" disabled>
          </div>
          <div class="form-group">
            <label>Fecha Vencimiento:</label>
            <input type="text" class="form-control" v-model="fecha_vencimiento" disabled>
          </div>
          <div class="form-group">
            <label>Fecha Devolución:</label>
            <input type="text" class="form-control" v-model="fecha_devolucion" disabled>
          </div>
          <div class="form-group">
            <label>Observaciones:</label>
            <input type="text" class="form-control" v-model="item.prm_observaciones" disabled>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-md-12">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Aceptar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import moment from 'moment';

  export default {
    components: {
    },
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        fecha: null
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.search = this.$route.params.search;      
      this.getItem(this.$route.params.id);
    },
    methods: {
      setClose(close){
        this.close = close;
      },
      getItem(id){
        let uri = '/prestamos/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
          this.fecha = moment(response.data.prm_fecha).format('DD/MMM/YYYY hh:mm:ss A');
          if (response.data.prm_fecha_vencimiento){
            this.fecha_vencimiento = moment.utc(response.data.prm_fecha_vencimiento).format('DD/MMM/YYYY');
          }
          if (response.data.prm_fecha_devolucion){
            this.fecha_devolucion = moment(response.data.prm_fecha_devolucion).format('DD/MMM/YYYY hh:mm:ss A');
          }

          let uri = '/usuarios/edit/' + response.data.usu_id;
          this.axios.get(uri).then((usu) => {
            var usu_nmbre = usu.data.usu_nmbre + " " + usu.data.usu_aplldo + " (" + usu.data.usu_cdgo + ")";
            this.$set(this.item, 'usu_nmbre', usu_nmbre);
          });
        });
      },
      cancel(){
        this.$router.replace({ name: 'DisplayPrestamo', params: { search: this.search } });
      }
    } // END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>